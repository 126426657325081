import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PIPPLogo from '../images/pipp-logo.svg'

const PIPP = () => (
  <Layout>
    <SEO title="Patternmaking in Public Places" />

    <main className="pipp-main">
      <div className="two-column-main">
        <div className="side-container">
          <img
            src={PIPPLogo}
            alt="Patternmaking in Public Places"
            className="side-image pipp-image"
          />
        </div>
        <div className="side-container">
          <article>
            <p>
              Through &quot;Patternmaking in Public Places,&quot; I am bringing
              patternmaking -- a craft usually hidden away in factories, fashion
              schools and other private spaces -- out into the public view.
              Patterns are the shapes of our bodies and our dreams, and they
              belong to us. Please visit
              {" "}
              <a href="www.patternmakinginpublicplaces.com">
                www.patternmakinginpublicplaces.com
              </a>
              {" "}
              to learn more.
            </p>
          </article>
        </div>
      </div>
    </main>
  </Layout>
)

export default PIPP
